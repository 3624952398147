
import {defineComponent, onMounted, onUnmounted, ref} from "vue";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import AuthService from "@/services/AuthService";
import store from "@/store";
import { useRouter } from 'vue-router';


export default defineComponent({
  name: "error-401",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;

    function goToHome () {
      router.push({name: "login"});
    }

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {
      goToHome
    };

  },
});
